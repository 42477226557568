<template>
  <div>
    <!-- <el-table border
              stripe
              :data="tableData"
              highlight-current-row
              :max-height="$store.state.tableHeight"
              style="width: 100%">
      <el-table-column prop="edu_school_name"
                       align="center"
                       label="学校名" />
      <el-table-column prop="edu_user_id"
                       align="center"
                       width="100"
                       label="教师ID" />
      <el-table-column prop="edu_user_name"
                       align="center"
                       width="140"
                       label="教师姓名" />
      <el-table-column prop="subject_name"
                       align="center"
                       width="140"
                       label="科目" />
      <el-table-column prop="paper_count"
                       align="center"
                       label="教师试卷ID" />
      <el-table-column prop="paper_score"
                       align="center"
                       label="试卷分数" />
      <el-table-column prop="paper_description"
                       align="center"
                       label="试卷描述" />
      <el-table-column prop="create_time"
                       align="center"
                       label="提交时间" />
      <el-table-column prop="user_paper_count"
                       align="center"
                       label="学生做卷次数" />
      <el-table-column prop="user_score_rate"
                       align="center"
                       label="学生得分率" />
    </el-table> -->
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>

export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'cz_statistics_teacherDetail_admin',
      tableColumns: [
        // { prop: 'edu_school_name', align: 'center', label: '学校名', width: '', showToopic: false },
        // { prop: 'edu_user_id', align: 'center', label: '教师ID', width: '100', showToopic: true },
        // { prop: 'edu_user_name', align: 'center', label: '教师姓名', width: '', showToopic: false },
        // { prop: 'subject_name', align: 'center', label: '科目', width: '', showToopic: false },
        { prop: 'edu_paper_id', align: 'center', label: '教师试卷ID', width: '', showToopic: false },
        { prop: 'paper_score', align: 'center', label: '试卷分数', width: '', showToopic: false },
        { prop: 'paper_name', align: 'center', label: '试卷名称', width: '', showToopic: false },
        { prop: 'create_time', align: 'center', label: '提交时间', width: '', showToopic: false },
        { prop: 'user_paper_count', align: 'center', label: '学生做卷次数', width: '', showToopic: false },
        { prop: 'user_score_rate', align: 'center', label: '学生得分率', width: '', showToopic: false },
      ]
    }
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      var form = this.$route.query.form ? JSON.parse(this.$route.query.form) : {}
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v1/combination/stats_detail',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>